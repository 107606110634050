import * as React from "react"
import Nav from "./nav"

const menuLinks = [
  {
    "destination": "/menu/",
    "name": "Menu",
    "category": true
  },
  {
    "destination": "/news/",
    "name": "Novità",
    "category": true
  },
  {
    "destination": "/contatti/",
    "name": "Contatti",
    "category": false
  }
];

const linkClassName = 'font-medium px-8 py-2 rounded mx-1'
const otherLinksClassName = 'text-mint hover:bg-gray-100 focus:bg-gray-100'
const currentLinkClassName = 'text-white bg-mint'

const DesktopNav = () => {
  return (
    <Nav links={menuLinks} className={'hidden md:flex'} linkClassName={linkClassName} otherLinksClassName={otherLinksClassName} currentLinkClassName={currentLinkClassName} />
  )
}

export default DesktopNav
