import * as React from "react"
import Header from "./header"
import MobileNav from "./mobile-nav"
import Footer from "./footer"

const Template = ({ children }) => {
  return <>
    <Header />
    { children }
    <MobileNav />
    <Footer />
  </>
}

export default Template
