import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import DesktopNav from "./desktop-nav"

const Header = () => {
  return (
    <header className="p-4 mb-6 max-w-screen-lg mx-auto flex justify-center items-center md:justify-between">
      <Link to="/">
        <StaticImage src="../images/logotype.png" alt="Logo di Bodega de Lopez" placeholder="blurred" layout="fixed" width={144} />
      </Link>
      <DesktopNav />
    </header>
  )
}

export default Header
