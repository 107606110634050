import * as React from "react"
import { HomeIcon, ClipboardListIcon, SpeakerphoneIcon, PhoneIcon } from "@heroicons/react/outline"
import Nav from "./nav"

const menuLinks = [
  {
    "destination": "/",
    "name": <HomeIcon className={'h-6 w-6'} />,
    "category": false
  },
  {
    "destination": "/menu/",
    "name": <ClipboardListIcon className={'h-6 w-6'} />,
    "category": true
  },
  {
    "destination": "/news/",
    "name": <SpeakerphoneIcon className={'h-6 w-6'} />,
    "category": true
  },
  {
    "destination": "/contatti/",
    "name": <PhoneIcon className={'h-6 w-6'} />,
    "category": false
  }
];

const linkClassName = 'font-medium px-6 py-2 rounded mx-1'
const otherLinksClassName = 'text-mint hover:bg-gray-100 focus:bg-gray-100'
const currentLinkClassName = 'text-white bg-mint'

const MobileNav = () => {
  return (
    <Nav links={menuLinks} className={'absolute bottom-0 flex justify-around w-full py-4 border-t md:hidden'} linkClassName={linkClassName} otherLinksClassName={otherLinksClassName} currentLinkClassName={currentLinkClassName} />
  )
}

export default MobileNav
