import * as React from "react"

const Footer = () => {
  const year = (new Date()).getFullYear();
  return (
    <footer className="max-w-screen-lg mx-auto italic text-xs text-center p-4 border-t mt-6">© { year } Bodega de Lopez di Lopez Valdes Legna - Via Lorenzo Delleani 23 - 13900 Biella (BI) - P.IVA: 02623480023</footer>
  )
}

export default Footer
