import * as React from "react"
import { Link } from "gatsby"

const Nav = ({ links, className, linkClassName, currentLinkClassName, otherLinksClassName }) => {
  const getClassByProps = (category) => ({ isCurrent, isPartiallyCurrent }) => {
    return { className: linkClassName + ' ' + (isCurrent || (category && isPartiallyCurrent) ? currentLinkClassName : otherLinksClassName) }
  }

  return (
    <nav className={className}>
      {links.map((link) => (
        <Link getProps={getClassByProps(link.category)} to={link.destination} key={link.destination} partiallyActive={link.category}>{link.name}</Link>
      ))}
    </nav>
  )
}

export default Nav
